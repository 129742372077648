import Jeff from './assets/members/jeff.png'
import Matt from './assets/members/matt.png'
import Jamie from "./assets/members/jamie.JPG";
import Christian from "./assets/members/christian.JPG"
import Brett from "./assets/members/brett.JPG";
import Rishi from "./assets/members/rishi.jpg"
import Henry from "./assets/members/henry.JPG"
import Shreya from "./assets/members/shreya.JPG";
import Om from"./assets/members/om.JPG";
import Ben from "./assets/members/ben.JPG";
import Andres from "./assets/members/andres.JPG";
import MatthewB from "./assets/members/matthewB.JPG";
import Shahmeer from "./assets/members/shahmeer.JPG";
import Troy from "./assets/members/troy.JPG";
import Owen from "./assets/members/owen.JPG";
import MattD from "./assets/members/matt.JPG"

import Colin from "./assets/members/colin.JPG";
import Khathab from "./assets/members/khathab.JPG";
import John from "./assets/members/john.JPG";
import Shiven from "./assets/members/shiven.JPG";
import Dhruv from "./assets/members/dhruv.JPG";
import Alex from "./assets/members/alex.JPG"
import placeholder from "./assets/logo_plane.png";


const MechanicalMembers = [
  { name: 'Matthew Soldo', title: 'Mechanical Co-Captain', year: '5th Year Mechanical Engineering w/ Co-Op', image: Matt },
  { name: 'Jamieson Eng', title: 'Mechanical Co-Captain', year: '5th Year Mechanical Engineering w/ Co-Op ', image: Jamie },
  { name: 'Christian Seabrook', title: 'Wing Sr. Lead', year: '5th Year Mechanical & Biomedical Engineering', image: Christian },
  { name: 'Brett MacDonald', title: 'Fuselage Sr. Lead', year: '5th Year Mechanical Engineering w/ Co-Op', image: Brett },
  { name: 'Rishi Patel', title: 'Tail Sr. Lead', year: '4th Year Mechatronics Engineering', image: Rishi },
  { name: 'Henry Li', title: 'Landing Gear Sr. Lead', year: '3rd Year Mechanical and Biomedical Engineering ', image: Henry },
  { name: 'Shreya Chandel', title: 'PADA Mechanical Sr. Lead', year: '5th Year Mechanical Engineering w/ Co-Op ', image: Shreya },
  { name: 'Om Bhatt', title: 'GTV Sr. Lead', year: '3rd Year Mechatronics Engineering and AISE', image: Om },
  { name: 'Ben Secco', title: 'Wing Jr. Lead', year: '2nd Year Civil Engineering', image: Ben },
  { name: 'Andres Monroy', title: 'Fuselage Jr. Lead', year: '4th Year Mechanical Engineering w/ Co-Op ', image: Andres },
  { name: 'Matthew Bentley', title: 'Tail Jr. Lead', year: '4th Year Mechanical Engineering w/ Co-Op ', image: MatthewB },
  { name: 'Shahmeer Atif', title: 'Landing Gear Jr. Lead', year: '2nd Year Electrical Engineering', image: Shahmeer },
  { name: 'Troy Leishman', title: 'PADA Mechanical Jr. Lead', year: '1st Year Engineering', image: Troy },
  { name: 'Owen Ellis', title: 'PADA Mechanical Jr. Lead', year: '2nd Year Mechatronics Engineering and Astrophysics ', image: Owen },
  { name: 'Matt (Le Hoang Anh) Doan', title: 'GTV Jr. Lead', year: '3rd Year Mechatronics Engineering', image: MattD },

]

const ControlsMembers = [
  { name: 'Jeff St. Jean', title: 'Controls Co-Captain', year: '6th Year Mechatronics Engineering w/ Co-Op & CS ', image: Jeff },
  { name: 'Colin Smith', title: 'Controls Co-Captain', year: '5th Year Mechatronics Engineering w/ Co-Op & CS', image: Colin },
  { name: 'Khathab Hamed', title: 'PADA Controls Sr. Lead', year: '4th Year Mechatronics Engineering & CS', image: Khathab },
  { name: 'John Langlois', title: 'PA Controls Sr. Lead', year: '4th Year Software Engineering w/ Co-Op ', image: John },
  { name: 'Shiven Sharma', title: 'PADA Controls Jr. Lead', year: '2nd Year Software Engineering', image: Shiven },
  { name: 'Dhruv Patel', title: 'Embedded Controls Jr. Lead', year: '4th Year Mechatronics and Biomedical Engineering', image: Dhruv },
  { name: 'Alex Fernandes', title: 'Ground Station Jr. Lead', year: '2nd Year Electrical Engineering & AISE ', image: Alex },


] 

export {MechanicalMembers, ControlsMembers};
